/* Styles for the scrollbar */
.container {
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.mobileExperienceList {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 200px);
  overflow-y: auto; /* handle overflow by scrolling */
}


.experienceList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Adjust this based on your condition for less than 3 items */
  overflow-y: scroll;
  width: 50%;
  height: calc(100% - 62px);
  border: 1px solid #F2EDE8;
  background-color: #F2EDE8;
  border-radius: 10px;
  direction: rtl;
}

.experienceItem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
  width: 75%;
  direction: ltr;
}

.icon {
  margin-right: 20px;
  margin-left: 20px;
  font-size: 50px;
  color: #23404F;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.experienceList::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  background-color: #F2EDE8; /* Color of the track */

}

.experienceList::-webkit-scrollbar-track {
  background-color: #F2EDE8; /* Color of the track */
}

.experienceList::-webkit-scrollbar-thumb {
  background-color: #F2EDE8; /* Color of the thumb */
  border-radius: 10px; /* Radius of the thumb */
}

.experienceList::-webkit-scrollbar-thumb:hover {
  background-color: rgb(9, 18, 22);
}

.fadeIn {
    opacity: 0;
    transition: opacity 1s;
    transition-delay: 1s; /* Adjust the delay here */
  }
  
  .fadeInFromLeft {
    opacity: 0;
    transform: translateX(-30px);
    transition: opacity 1s, transform 1s;
    transition-delay: .5s; /* Adjust the delay here */
  }
  
  .fadeIn.visible, .fadeInFromLeft.visible {
    opacity: 1;
    transform: translateX(0);
  }
  
/* Existing Media Query */
@media (max-width: 768px) {
  .experienceList {
    width: 100%;
    max-height: calc(100vh - 62px);
    overflow-y: scroll; 
  }
  .imageContainer {
    display: none;
  }
  .fadeIn{
    transition-delay: .25s;
  }
}
