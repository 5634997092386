.blogPage {
    background-color: #FFFFFF;
    height: 50vh;
    position: relative;
}

.contentWrapperB {
    display: flex; /* Enable flex on the container */
    align-items: center; /* Center items vertically */
    height: 100%; /* Take the full height of the parent */
    padding: 0 40px; /* Add some padding on the sides */
}

.imgContainerB,
.textContainerB {
    opacity: 0; /* Start invisible */
    transition: opacity 1s ease-in-out 1s; /* Add a delay directly in the transition property */
}

.imgContainerB {
    height: 50%;
    width: 25%; /* Adjust width as needed */
}
.textContainerB p{
    font-size: 2em;
    color: #425A67;
}

.textContainerB {
    padding-left: 20px; /* Add some space between the text and the image */
}

.imgBlog {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.imgContainerB.visible,
.textContainerB.visible {
    opacity: 1; /* End visible */
}
