.timeline-small-item {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%; 
    flex-grow: 1;
}

.timeline-small-item:before {
    content: "";
    position: absolute;
    bottom: 0; /* Adjusted from top to bottom */
    right: 0; /* Centered horizontally */
    width: 1em;
    margin-right: 50px;
    height: 0;
    background-color: #ffffff;
    z-index: 100;
    opacity: 0;
    transition: opacity 1s ease-in-out, height 1s ease-in-out;
    transition-delay: 1s;
}
.timeline-small-item.is-visible:before {
    opacity: 1;
    height: 100%; /* Adjust height as necessary */
    top: 50%;
}
.timeline-small-item:last-child:before {
    height: 55%; /* Adjust this value to make the last line shorter */
}
.timeline-small-dot {
    width: 5em;
    height: 5em;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    margin-right: 20px; /* Margin to separate dot from the right side */
    position: absolute;
    right: 0; /* Position dot to the right */
    z-index: 101;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    transition-delay: 1s;
}

.timeline-small-item.is-visible .timeline-small-dot {
    opacity: 1;
}

.invert {
    filter: invert(1);
}

.timeline-small-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #FFFFFF;
    font-size: 2em;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    transition-delay: 1.5s;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: auto;
}

.timeline-small-item.is-visible .timeline-small-label {
    opacity: 1;
}

.timeline-small-label img {
    width: 80%;
    height: auto;
    display: block;
    margin: 0 auto 10px;
}

.timeline-small-logo-container,
.timeline-small-text-container {
    flex: 1;
    padding: 10px;
}

.timeline-small-logo-container {
    text-align: center;
}
.timeline-small-text-container h3{
    font-size: 1.5em;
}
.timeline-small-text-container h3,
.timeline-small-text-container p {
    margin: 0;
    width: 80%;
    
}

.timeline-small-text-container p {
    font-size: 1em;
}

/* ... Other Styles ... */
@media (max-width: 630px) {
    .timeline-small-item:before {
        width: 10px;
        margin-right: 38px;
    }
    .timeline-small-text-container h3{
        font-size: .8em;
        margin-right: 5px;
    }.timeline-small-text-container p {
        font-size: .6em;
        margin-right: 15px;
    }
    .timeline-small-dot {
        width: 3em;
        height: 3em;
    }
    .timeline-small-item:last-child:before {
        height: 55%; /* Adjust this value to make the last line shorter */
    }
}
