.pub-card {
  display: flex;
  width: 25vw; 
  height: 25vh; 
  min-width: 300px;
  min-height: 200px;
  border: 0px solid; 
  overflow: hidden; 
  margin-right: 10%;
  margin-top: 40px;
}
.pub-card-hidden {
  opacity: 0;
}

.text-section {
  flex: 0 0 60%; 
  background-color: #F2EDE8; 
  position: relative;
  display: flex; /* Added flex display */
  flex-direction: column; /* Organize children (title and comment) in a column */
  justify-content: center; /* Center children vertically */
}



.title-box {
  position: absolute;
  right: 0;
  top: 0;
  width: 75%; 
  padding: 5px;
  background-color: #425A67; 
  color: #FFFFFF;
  text-align: center; 
  border: 0px solid; 
}

.comment-box { /* Styling for the new comment box */
  margin-right: auto; /* Push comment to the left */
  padding: 15px; /* Optional: for some space around the text */
  text-align: left; /* Align text to the left within the box */
  color: #425A67;
}

.image-section {
  flex: 0 0 40%; 
  background-color: #F2EDE8;
  position: relative;
}
.img-container img {
  width: 100%; /* Set width to fill the container */
  height: 100%; /* Set height to fill the container */
  object-fit: cover; /* Maintain aspect ratio while covering the area */
}

.button-box {
  position: absolute; /* Absolute positioning within text-section */
  bottom: 0; /* Positioned at the bottom */
  left: 0; /* Positioned to the left */
}

.button-box a {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none; /* Removes underline from the link */
  background-color: #D1C0B5; /* Example color, change as needed */
  color: #425A67; /* Text color */
  border-radius: 5px; /* Optional: for rounded corners */
  transition: background-color 0.3s; /* Optional: for hover effect */
}

.button-box a:hover {
  background-color: #ad9f97; /* Darker shade for hover effect */
}






.background-box {
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%; /* Spans the whole width of the parent */
  height: 75%; /* Set height to 50% of the parent */
  background-color: #D1C0B5; /* Set to desired background color */
  z-index: 1; /* Set z-index lower than img-container */
}

.img-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%; 
  height: 75%; 
  z-index: 2; /* Ensure img-container is above background-box */
}
@media (max-width: 630px) {
  .title-box {
    font-size: 3vw;
  }
  .comment-box p{ /* Styling for the new comment box */
    margin: 0;
  }
  .img-container {
    width: 90%
  }
}