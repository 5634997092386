
  .grow {
    transition: height 0.5s ease-in-out;
  }

  .homePage {
    position: relative;
    width: 80%;
    margin: 0 auto;
}


  @media (max-width: 1050px) {
    .homePage {
      width: 100%;
    }
    .about-me{
      height: 50vh;
    }
    .buttonContainer {
      flex-direction: row; /* Keep buttons horizontal */
      flex-wrap: wrap; /* Allow buttons to wrap into next line */
      justify-content: center; /* Center buttons horizontally */
      gap: 5px; /* Adjust gap for small screens */
  }
  }

