.card-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch; /* Ensure child containers take full width */
    padding: 0;
}

.card-right, .card-left {
    display: flex;
    width: 100%; /* Take full width of parent */
}

.card-right {
    justify-content: flex-end; /* Align PubCard to the right */
    margin-right: 25%;
}

.card-left {
    justify-content: flex-start; /* Align PubCardL to the left */
}
