.titleText {
    color: #425A67;
    font-size: 5em;
    animation-delay: 0.5s; /* or however long you want the delay to be */
}

/* on mobile */
@media (max-width: 600px) {
    .titleText {
        font-size: 3em;
    }
}