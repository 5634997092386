.pub-cardL {
    display: flex;
    width: 25vw; 
    height: 25vh; 
    min-width: 300px;
    min-height: 200px;
    border: 0px solid; 
    overflow: hidden; 
    margin-left: 10%;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .pub-cardL-hidden {
    opacity: 0;
  }
  
  
  .text-sectionL {
    flex: 0 0 60%;
    background-color: #F2EDE8;
    position: relative;
    display: flex; /* Added flex display */
    flex-direction: column; /* Organize children (title and comment) in a column */
    justify-content: center; /* Center children vertically */
  }
  
  .title-boxL {
    position: absolute;
    top: 0;
    left: 0;
    width: 75%; 
    padding: 5px;
    background-color: #425A67;
    color: #FFFFFF;
    text-align: center;
    border: 0px solid; 
  }
  
  .comment-boxL { /* Styling for the new comment box */
    margin-left: auto; /* Push to the right */
    padding: 15px; /* Optional: for some space around the text */
    text-align: right; /* Align text to the right within the box */
    margin-right:  10px;
    color: #425A67;

  }
  
  .image-sectionL img {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
  }
  .button-boxL {
    position: absolute; /* Absolute positioning within text-section */
    bottom: 0; /* Positioned at the bottom */
    right: 0; /* Positioned to the left */
  }
  
  .button-boxL a {
    display: inline-block;
    padding: 10px 20px;
    text-decoration: none; /* Removes underline from the link */
    background-color: #D1C0B5; /* Example color, change as needed */
    color: #425A67; /* Text color */
    border-radius: 5px; /* Optional: for rounded corners */
    transition: background-color 0.3s; /* Optional: for hover effect */
  }
  
  .button-boxL a:hover {
    background-color: #ad9f97; /* Darker shade for hover effect */
  }
  .image-sectionL {
    flex: 0 0 40%; 
    background-color: #F2EDE8;
    position: relative; /* Add relative positioning */
  }
  
  .img-containerL {
    position: absolute;
    top: 0;
    RIGHT: 0;
    width: 70%; 
    height: 75%; 
    z-index: 2; /* Ensure img-container is above background-box */
  }
  
  .img-containerL img {
    width: 100%; /* Set width to fill the container */
    height: 100%; /* Set height to fill the container */
    object-fit: cover; /* Maintain aspect ratio while covering the area */
  }

  .background-boxL {
    position: absolute;
    top: 15%;
    left: 0;
    width: 100%; /* Spans the whole width of the parent */
    height: 75%; /* Set height to 50% of the parent */
    background-color: #D1C0B5; /* Set to desired background color */
    z-index: 1; /* Set z-index lower than img-container */
  }

  @media (max-width: 630px) {
    .title-boxL {
      font-size: 3vw;
    }
    .comment-boxL p{ /* Styling for the new comment box */
      margin: 0;
    }
    .img-containerL {
      width: 90%
    }

  }