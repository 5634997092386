/* TimelineItem.css */

/* Global Styles for Timeline Item */
.timeline-item {
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;
}

/* Pseudo-elements for Timeline Item */
.timeline-item:before {
  content: "";
  position: absolute;
  top: 200%;
  left: 40%;
  width: 1em;
  height: 0;
  background-color: #ffffff;
  z-index: 100;
  opacity: 0;
  transition: opacity 1s ease-in-out, height 1s ease-in-out;
  transition-delay: 1s;
}

.timeline-item.is-visible:before {
  opacity: 1;
  height: 100%;
  top: 50%;
}

.timeline-item:last-child:before {
  height: 50%;
}

/* Timeline Dot Styles */
.timeline-dot {
  width: 5em;
  height: 5em;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  margin: auto;
  position: relative;
  z-index: 101;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  transition-delay: 1s;
}

.timeline-item.is-visible .timeline-dot {
  opacity: 1;
}

/* Invert Color Utility */
.invert {
  filter: invert(1);
}

/* Timeline Label Styles */
.timeline-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #FFFFFF;
  font-size: 2em;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  transition-delay: 1.5s;
  width: 700%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.timeline-item.is-visible .timeline-label {
  opacity: 1;
}

.timeline-label.left {
  right: 100%;
  margin-right: 20px; 
}

.timeline-label.right {
  left: 100%;
  margin-left: 20px;
}

/* Image Styles within Timeline Label */
.timeline-label img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 10px;
}

/* Logo and Text Container Styles */
.logo-container,
.text-container {
  flex: 1;
  padding: 10px;
}

.logo-container {
  text-align: center;
}

/* Text Styles within Text Container */
.text-container h3,
.text-container p {
  margin: 0;
}

.text-container p {
  font-size: 1em;
}

.text-container.right {
  text-align: right;
}



/* Media Queries */
@media (max-width: 630px) {
  .timeline-item:before {
    width: 10px;
  }

  .timeline-dot {
    width: 1.5em;
    height: 1.5em;
  }
}
