/* Header.css */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto; /* Center the header */
    position: sticky;
    top: 0;
}

.titleContainer {
    display: flex;
    align-items: center;
    font-size: 2rem; /* Or use calc, vw, etc. to make this scalable */
    margin: 25px;
}

.buttonContainer {
    display: flex;
    gap: 10px;
    margin-right: 25px;
}

/* Responsive adjustments */
@media (max-width: 1050px) {
    .header {
        width: 100%;
        flex-direction: column;
        text-align: center; /* Center text for small screens */
    }

    .titleContainer {
        font-size: 1.8rem; /* Adjust font size for small screens */
        margin-bottom: 10px; /* Space between title and buttons */
        margin-top: 10px;
    }

    .buttonContainer {
        flex-direction: row; /* Keep buttons horizontal */
        flex-wrap: wrap; /* Allow buttons to wrap into next line */
        justify-content: center; /* Center buttons horizontally */
        gap: 5px; /* Adjust gap for small screens */
    }
}
