.aboutMeContent {
  display: flex;
  flex-direction: row;
  height: 50vh;
  margin: 0;
  padding: 0;
}

.photoContainer {
  flex: 1;
  overflow: hidden;
}

.photo {
  width: 100%;
  height: auto;
  min-height: 100%;
  object-fit: cover;
}

.textContainer {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #F2EDE8;
  z-index: 2;
}

.title {
  font-size: 2em;
}

.subtext {
  font-size: 1.2em;
  text-align: center;
  padding: 0 10%;
}
/* ... (your existing CSS for larger screens) ... */

@media (max-width: 768px) {
  .aboutMeContent {
      position: relative;
      height: auto;
  }

  .textContainer {
      position: absolute;
      top: 0;
      left: 0;
      height: 50vh; /* You might want to adjust this */
      z-index: 1;
  }

  .photoContainer {
      position: relative;
      z-index: 2;
      margin: 10px; /* Maintain this margin if you want space around the photo */
      height: 30vh; /* Set the height you want for the image */
  }

  .photo {
      width: 100%; /* Will be constrained by photoContainer's height */
      height: 100%; /* Takes up the entire height of its container */
      object-fit: cover; /* Adjust as you see fit */
      border-radius: 10px;
  }

  .overlay {
      position: absolute;
      top: 28vh; /* Offset from top equals the height of .photoContainer */
      left: 10px;
      height: 25vh;
  }

  .title, .subtext {
      padding: 0;
  }
}

